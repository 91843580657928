// import '@/assets/font/font.css';
import 'element-ui/lib/theme-chalk/index.css';

import Vue from 'vue';

import axios from 'axios';
import * as echarts from 'echarts';
import ElementUI from 'element-ui';
import VueAMap from 'vue-amap';
import VueRouter from 'vue-router';
import scroll from 'vue-seamless-scroll';

import router from '@/router/index.js';
import dataV from '@jiaminghi/data-view';
import '@/assets/css/font.css'
import App from './App.vue';

Vue.use(scroll)

Vue.use(ElementUI);
Vue.use(dataV)

Vue.use(VueRouter)
Vue.use(VueAMap);

VueAMap.initAMapApiLoader({
  key: 'a83fd2f443370471dc9625c47b8a17b9',
  plugin: [],
  v: '1.4.4'
});
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = axios
Vue.prototype.$imgUrl = '../../assets/'

Vue.axios = axios
new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')
