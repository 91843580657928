<template>
    <div class="header">
        <div class="left">
            <img :src="require('../assets/logo2.png')" alt="" class="logo">
            <!-- <img :src="require('../assets/name.png')" alt="" class="name" mode="heightFix"> -->
        </div>
        <div class="right">
            <div class="login_state" v-if="userinfo.userType == 1" @click="showbox = !showbox"
                v-html="stateIndex == 1 ? '在线' : stateIndex == 2 ? '忙碌' : stateIndex == 3 ? '离线' : ''"
                :class="stateIndex == 2 ? 's2' : stateIndex == 3 ? 's3' : ''"></div>
            <div class="statebox" v-if="showbox">
                <div @click="changeState(1)">在线</div>
                <div @click="changeState(2)">忙碌</div>
                <div @click="changeState(3)">离线</div>
            </div>
            <div class="status">{{ userinfo.deptName }}</div>
            <img :src="userinfo.headImg" alt="" class="headImg_t"  @click="showloginOut = !showloginOut">
            <div class="loginout" v-if="showloginOut">
                <div @click="loginout">退出登录</div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { updateWebUserStatus } from '@/api/login';
export default {
    name: 'Header',
    props: {

    },
    data() {
        return {
            imgUrl: this.$imgUrl,
            userinfo: {},
            showbox: false,
            showloginOut:false,
            stateIndex: 1,
        };
    },
    created() {
        this.userinfo = JSON.parse(localStorage.getItem('userinfo'));
        this.stateIndex = this.userinfo.status
    },
    mounted() {
        let that = this;
        document.addEventListener('click', function (e) {
            if (e.target.className != 'statebox' && e.target.className != 'login_state' && e.target.className != 'login_state s2' && e.target.className != 'login_state s3') {
                that.showbox = false;
            }
            if (e.target.className != 'headImg_t') {
                that.showloginOut = false;
            }

        })

    },

    methods: {
        loginout(){
            localStorage.clear();
            location.reload()
        },
        changeState(index) {
            this.stateIndex = index;
            let params = {
                id: localStorage.getItem('userid'),
                status: this.stateIndex
            }
            updateWebUserStatus(params).then(res => {
                if(res.status == 200){
                    let userinfo = this.userinfo;
                    userinfo.status = this.stateIndex;
                    localStorage.setItem('userinfo',JSON.stringify(userinfo))
                }

            }).catch(err => {
                this.$message({
                    message: err.msg,
                    type: 'warning'
                })
            })
        }
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header {
    width: 100%;
    height: 60px;
    background-color: #064564;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px 0 20px;
    box-sizing: border-box;

    .left {
        display: flex;
        align-items: center;

        .logo {
            height: 60px;
        }

        .name {
            height: 40px;
            margin-left: 10px;
        }
    }

    .right {
        display: flex;
        align-items: center;
        color: #fff;

        // position: relative;
        .login_state {
            font-size: 16px;

            padding: 4px 16px;
            background-color: #10D144;
            border-radius: 20px;
            cursor: pointer;
        }

        .s2 {
            background-color: #E64040;
        }

        .s3 {
            background-color: #9F9F9F;
        }

        .statebox {
            position: fixed;
            top: 62px;
            z-index: 777;
            background-color: #FFFFFF;
            border: 1px solid #E6E6E6;
            color: #222;
            border-radius: 20px;

            div {
                font-size: 18px;
                font-weight: 600;
                padding: 4px 26px;
                cursor: pointer;

                &:nth-child(2) {
                    color: #E64040;
                    border-top: 1px solid #E6E6E6;
                }

                &:nth-child(3) {
                    color: #9F9F9F;
                    border-top: 1px solid #E6E6E6;
                }
            }
        }
        .loginout{
            position: fixed;
            top: 62px;
            right: 6px;
            z-index: 777;
            background-color: #FFFFFF;
            border: 1px solid #E6E6E6;
            color: #222;
            border-radius: 2px;
            div {
                font-size: 18px;
                font-weight: 600;
                padding: 4px 26px;
                cursor: pointer;
            }
        }

        .status {
            font-size: 16px;
            font-weight: 600;
            padding: 0 10px;

        }

        .headImg_t {
            width: 42px;
            height: 42px;
            cursor: pointer;
        }
    }
}
</style>
  