<template>
    <div class="sideNav">
        <div class="item" v-for="(nav, index) in navList" :key="index">
            <div class="nav_item" :class="$route.path == nav.url ? 'active' : ''" @click="clickNav(index, 1)">
                <img :src="nav.img_index" alt="" v-if="$route.path == nav.url">
                <img :src="nav.img" alt="" v-else>
                <span>{{ nav.title }}</span>
                <img :src="require('../assets/down.png')" alt="" class="down" v-if="nav.isOpen">
            </div>
            <div class="child" v-if="nav.isOpen">
                <div class="nav_item_child" :class="$route.path == nav_child.url ? 'active' : ''"
                    v-for="(nav_child, index_c) in nav.children" :key="index_c" @click="clickNav(index, 2, index_c)">
                    {{ nav_child.title }}</div>
            </div>
        </div>


    </div>
</template>
  
<script>
export default {
    name: 'SideNav',
    props: {

    },
    data() {
        return {
            activeIndex: 2,
            userType: 1,
            navList: [
                {
                    img: require('../assets/nav1.png'),
                    img_index: require('../assets/nav1_index.png'),
                    title: '消息',
                    children: null,
                    url: 'information',
                    isOpen: false,
                },
                {
                    img: require('../assets/nav2.png'),
                    img_index: require('../assets/nav2_index.png'),
                    title: '管理',
                    children: null,
                    url: 'manage',
                    isOpen: false,
                },
                {
                    img: require('../assets/nav3.png'),
                    img_index: require('../assets/nav3_index.png'),
                    title: '我的',
                    url: '',
                    isOpen: false,
                    children: [
                        {
                            title: '接访板块数据',
                            url: 'approach',
                            active: false,
                        },
                        {
                            title: '导出聊天记录',
                            url: 'exportchatting',
                            active: false,
                        },
                        {
                            title: '黑名单记录',
                            url: 'blacklist',
                            active: false,
                        }
                    ]
                }
            ],
            navList_admin: [
                {
                    img: require('../assets/nav7.png'),
                    img_index: require('../assets/nav7_index.png'),
                    title: '聊天数据统计',
                    children: null,
                    url: 'chatStatistics',
                    isOpen: false,
                },

                {
                    img: require('../assets/nav5.png'),
                    img_index: require('../assets/nav5_index.png'),
                    title: '接访账户管理',
                    children: null,
                    url: 'reception',
                    isOpen: false,
                },
                {
                    img: require('../assets/nav6.png'),
                    img_index: require('../assets/nav6_index.png'),
                    title: '来访账户管理',
                    url: 'visit',
                    isOpen: false,
                },
                {
                    img: require('../assets/nav8.png'),
                    img_index: require('../assets/nav8_index.png'),
                    title: '实名认证',
                    children: null,
                    url: 'autonym',
                    isOpen: false,

                },
                {
                    img: require('../assets/nav7.png'),
                    img_index: require('../assets/nav7_index.png'),
                    title: '聊天数据管理',
                    url: 'exportchatting',
                    isOpen: false,
                },
                {
                    img: require('../assets/nav8.png'),
                    img_index: require('../assets/nav8_index.png'),
                    title: '随手拍',
                    children: null,
                    url: 'feedback',
                    isOpen: false,

                },
                {
                    img: require('../assets/nav4.png'),
                    img_index: require('../assets/nav4_index.png'),
                    title: '布局及接访设置',
                    children: null,
                    url: 'set',
                    isOpen: false,
                }
            ]
        };
    },
    created() {
        this.userType = localStorage.getItem('userType');
        let userinfo = JSON.parse(localStorage.getItem('userinfo'));
        let userAccount = userinfo.userAccount;
        if (this.userType != 1) {
            if(userAccount == 'dataadmin'){
                let arr = [];
                arr.push(this.navList_admin[0])
                this.navList = arr
            }else{
                this.navList = this.navList_admin
            }
           
        }
    },
    methods: {
        clickNav(index, lev, index_c) {
            if (lev == 1) {
                this.activeIndex = index;
                if (this.navList[index].children != null) {
                    let newObj = this.navList[index];
                    newObj.isOpen = !newObj.isOpen;
                    this.$set(this.navList, index, newObj)
                } else {
                    this.$router.push({name:this.navList[index].url})
                }
            } else {
                this.$router.push({name:this.navList[index].children[index_c].url})
            }
        },
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.sideNav {
    width: 190px;
    height: calc(100% - 60px);
    background-color: #064564;

    .nav_item {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: #fff;
        cursor: pointer;
        position: relative;

        img {
            width: 15px;
            height: 15px;
        }

        span {
            margin-left: 4px;
        }

        .down {
            width: 26px;
            height: 26px;
            position: absolute;
            right: 20px;
        }
    }

    .child {
        width: 100%;
        color: #fff;

        .nav_item_child {
            width: 100%;
            height: 44px;
            text-align: center;
            line-height: 44px;
            cursor: pointer;
        }
    }

    .active {
        background-color: #fff;
        color: #064564;
    }
}
</style>
  