<template>
  <div id="app">
    <Header v-if="$route.meta.showheader" />
    <SideNav v-if="$route.meta.showslide" />
    <router-view />
  </div>
</template>

<script>

import Header from '@/components/header.vue'
import SideNav from '@/components/sideNav.vue'
export default {
  name: 'App',
  components: {
    Header,
    SideNav
  },
}
</script>

<style lang="scss">
html,
body,
#app {
  height: 100%;
  margin: 0;
  background-color: #F1F6F9;
  position: relative;
  font-family: '思源宋体';
  .sideNav {
    position: absolute;
    left: 0;
    top: 60px;
  }

  #container {
    height: calc(100% - 80px);
    width: calc(100% - 190px);
    margin-left: 190px;
    padding: 15px 20px;
    box-sizing: border-box;
  }


}

</style>
