//引入组件
import Vue from 'vue';

import Router from 'vue-router';
import VueRouter from 'vue-router';

// import Index from '@/views/index';

Vue.use(Router)
//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
    mode: 'hash',
    // base: process.env.NODE_ENV === "development" ? "./" : "/test",
    routes: [
        // { path: '/', component: Index },

        {
            path: '/login',
            name:'login',
            component: () => import('@/views/login'),
            meta: {
                showheader: false,
                showslide: false
            }
        },
        {
            path: '/information',
            name:'information',
            component: () => import('@/views/information'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/manage',
            name:'manage',
            component: () => import('@/views/manage'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/approach',
            name:'approach',
            component: () => import('@/views/approach'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/exportchatting',
            name:'exportchatting',
            component: () => import('@/views/exportchatting'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/blacklist',
            name:'blacklist',
            component: () => import('@/views/blacklist'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/set',
            name:'set',
            component: () => import('@/views/set'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/reception',
            name:'reception',
            component: () => import('@/views/reception'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/visit',
            name:'visit',
            component: () => import('@/views/visit'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/news',
            name:'news',
            component: () => import('@/views/news'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/banner',
            name:'banner',
            component: () => import('@/views/banner'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/chat',
            name:'chat',
            component: () => import('@/views/chat'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/feedback',
            name:'feedback',
            component: () => import('@/views/feedback'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/chatInfo',
            name:'chatInfo',
            component: () => import('@/views/chatInfo'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/largeScreen',
            name:'largeScreen',
            component: () => import('@/views/largeScreen'),
            meta: {
                showheader: false,
                showslide: false
            }
        },
        {
            path: '/virtual',
            name:'virtual',
            component: () => import('@/views/virtual'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/autonym',
            name:'autonym',
            component: () => import('@/views/autonym'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/chatStatistics',
            name:'chatStatistics',
            component: () => import('@/views/chatStatistics'),
            meta: {
                showheader: true,
                showslide: true
            }
        },
        {
            path: '/department',
            name:'department',
            component: () => import('@/views/department'),
            meta: {
                showheader: true,
                showslide: true
            }
        }
        
        


    ]
})
// router.beforeEach((to, from, next) => {
//     document.documentElement.scrollTop = 0
//     next()
// })
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next();
    } else {
        let userid = localStorage.getItem('userid');
        if (userid === null || userid === '') {
            next('/login');
        } else {
            next();
        }
    }
});

export default router